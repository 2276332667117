import React, { useState } from 'react';
import { Col, Row, Card, Breadcrumb } from '@themesberg/react-bootstrap';

import Content from '../Content';

import useSWR from 'swr';

import fetcher from '../../utils/fetcher';

const CoachesPage = () => {
  const [queryParams, setQueryParams] = useState({})

  // const { data: agencies, error: errorAgencies } = useSWR(['/api/agencies/', queryParams], fetcher);

  // if (!agencies || errorAgencies) {
  //   return null;
  // }

  return (
    <Content>
      <Breadcrumb listProps={{ className: "breadcrumb-primary breadcrumb-transparent" }}>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="#" active>Coaches</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col xl>
          <Card border="grey" className="border-3 shadow-sm mb-4">
            <Card.Header>
              <b>Coaches</b>
            </Card.Header>
            <Card.Body className="pb-0">
              <p>Coming soon</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Content>
  );
};

export default CoachesPage;
