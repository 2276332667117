import React, { useState } from 'react';
import { Col, Row, Card, Table, Breadcrumb } from '@themesberg/react-bootstrap';
import { useParams } from "react-router-dom";


import Content from '../Content';

import useSWR from 'swr';

import fetcher from '../../utils/fetcher';

const CoachDetailsPage = () => {
  const { athleteId } = useParams();

  const [queryParams, setQueryParams] = useState({})

  const { data: agencies, error: errorAgencies } = useSWR(['/api/agencies/', queryParams], fetcher);

  if (!agencies || errorAgencies) {
    return null;
  }

  return (
    <Content>
      <Breadcrumb listProps={{ className: "breadcrumb-primary breadcrumb-transparent" }}>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/athletes">Athletes</Breadcrumb.Item>
        <Breadcrumb.Item href="#" active>--athlete name--</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col xl={7}>
          <Card border="grey" className="border-3 shadow-sm mb-4">
            <Card.Header>
              <b>Personal</b>
            </Card.Header>
            <Card.Body className="pb-0">
            </Card.Body>
          </Card>
        </Col>

        <Col xl={5}>
          <Card border="grey" className="border-3 shadow-sm mb-4">
            <Card.Header>
              <b>Agency</b>
            </Card.Header>
            <Card.Body className="pb-0">
            </Card.Body>
          </Card>
        </Col>

      </Row>


      <Row>
        <Col xl={7}>
          <Card border="grey" className="border-3 shadow-sm mb-4">
            <Card.Header>
              <b>Sport</b>
            </Card.Header>
            <Card.Body className="pb-0">
            </Card.Body>
          </Card>
        </Col>

        <Col xl={5}>
          <Card border="grey" className="border-3 shadow-sm mb-4">
            <Card.Header>
              <b>Grades</b>
            </Card.Header>
            <Card.Body className="pb-0">
            </Card.Body>
          </Card>
        </Col>

      </Row>

    </Content>
  );
};

export default CoachDetailsPage;
