import React, { useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
} from '@themesberg/react-bootstrap';

import sports from '../../utils/sports';

import AddTennisAthleteForm from './AddTennisAthleteForm';
import AddSoccerAthleteForm from './AddSoccerAthleteForm';
// import AddCoachForm from './AddCoachForm';
// import profileTypes from '../../utils/profileTypes';

const InputForm = (props) => {
  const { selectedType, hideModal } = props;

  switch (selectedType) {
    case sports.TENNIS:
      return <AddTennisAthleteForm hideModal={hideModal} />;
    case sports.SOCCER:
      return <AddSoccerAthleteForm hideModal={hideModal} />;
    default:
      return null;
  }
}

const AddAthleteModal = (props) => {
  const [selectedType, setSelectedType] = useState("");
  const {
    isShowing,
    hide,
  } = props;

  return (
    <Modal as={Modal.Dialog} size='lg' centered show={isShowing} onHide={hide}>
      <Modal.Header>
        <div>
          <Modal.Title className="h6">Add Athlete</Modal.Title>
          <small>These details can always be changed</small>
        </div>
        <Button variant="close" aria-label="Close" onClick={hide} />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>Please select the sport of the athlete:</Form.Label>
              <Form.Select onChange={(selected) => setSelectedType((selected.target.value))} >
                <option value="">Select...</option>
                {Object.entries(sports).map(([_, value]) => <option key={value} value={value}>{value}</option>)}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-2'>
          <small>*Required fields</small>
        </Row>

        <InputForm
          selectedType={selectedType}
          hideModal={hide} />

      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" className="text-gray ms-auto" onClick={hide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddAthleteModal;
