import React from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';
import routes from './routes';

// Add your context here
import { AuthProvider } from './AuthContext';

// Error Pages
import NotFoundPage from './components/error/NotFoundPage';
import ServerErrorPage from './components/error/ServerErrorPage';

// Dashboard Pages
import HomePage from './components/home/HomePage';
import AthletesPage from './components/athletes/AthletesPage';
import AthleteDetailsPage from './components/athletes/AthleteDetailsPage';
import AgenciesPage from './components/agencies/AgenciesPage';
import AgencyDetailsPage from './components/agencies/AgencyDetailsPage';
import CoachesPage from './components/coaches/CoachesPage';
import CoachDetailsPage from './components/coaches/CoachDetailsPage';

import AddPage from './components/add/AddPage';
import AddAgencyPage from './components/add/AddAgencyPage';

import SettingsPage from './components/settings/SettingsPage';
import AccountPage from './components/account/AccountPage';
import MyAthletesPage from './components/myAthletes/MyAthletesPage';
import MyAgencyPage from './components/myAgency/MyAgencyPage';

// Auth Pages
import SigninPage from './components/auth/SigninPage';
import SignupPage from './components/auth/SignupPage';
import ForgotPasswordPage from './components/auth/ForgotPasswordPage';
// import ResetPasswordPage from './auth/ResetPasswordPage';

// // Components
import RouteWithLoaderPublic from './components/RouteWithLoaderPublic';
import RouteWithLoaderPrivate from './components/RouteWithLoaderPrivate';
// import RouteWithSidebarPublic from './components/RouteWithSidebarPublic';
// import RouteWithSidebarPrivate from './components/RouteWithSidebarPrivate';
import RouteWithSidebar from './components/RouteWithSidebar';


const App = () => {
  return (
    // Wrap Routes in context if needed
    <AuthProvider>
      <Routes>
        {/* Error Pages */}
        <Route
          path={routes.NotFoundPage.path}
          element={<RouteWithLoaderPublic component={NotFoundPage} />}
        />
        <Route
          path={routes.ServerErrorPage.path}
          element={<RouteWithLoaderPublic component={ServerErrorPage} />}
        />

        {/* Auth Pages */}
        <Route
          path={routes.SigninPage.path}
          element={<RouteWithLoaderPrivate component={SigninPage} />}
        />
        <Route
          path={routes.SignupPage.path}
          element={<RouteWithLoaderPrivate component={SignupPage} />}
        />
        <Route
          path={routes.ForgotPasswordPage.path}
          element={<RouteWithLoaderPrivate component={ForgotPasswordPage} />}
        />

        {/* Company Pages */}
        <Route
          path={routes.HomePage.path}
          element={<RouteWithSidebar component={HomePage} />}
        />
        <Route
          path={routes.AthletesPage.path}
          element={<RouteWithSidebar component={AthletesPage} />}
        />
        <Route
          path={routes.AthleteDetailsPage.path}
          element={<RouteWithSidebar component={AthleteDetailsPage} />}
        />
        <Route
          path={routes.AgenciesPage.path}
          element={<RouteWithSidebar component={AgenciesPage} />}
        />
        <Route
          path={routes.AgencyDetailsPage.path}
          element={<RouteWithSidebar component={AgencyDetailsPage} />}
        />
        <Route
          path={routes.CoachesPage.path}
          element={<RouteWithSidebar component={CoachesPage} />}
        />
        <Route
          path={routes.CoachDetailsPage.path}
          element={<RouteWithSidebar component={CoachDetailsPage} />}
        />

        <Route
          path={routes.AddPage.path}
          element={<RouteWithSidebar component={AddPage} />}
        />
        <Route
          path={routes.AddAgencyPage.path}
          element={<RouteWithSidebar component={AddAgencyPage} />}
        />

        <Route
          path={routes.SettingsPage.path}
          element={<RouteWithSidebar component={SettingsPage} isPrivateComponent />}
        />
        <Route
          path={routes.AccountPage.path}
          element={<RouteWithSidebar component={AccountPage} isPrivateComponent />}
        />
        <Route
          path={routes.MyAthletesPage.path}
          element={<RouteWithSidebar component={MyAthletesPage} isPrivateComponent />}
        />
        <Route
          path={routes.MyAgencyPage.path}
          element={<RouteWithSidebar component={MyAgencyPage} isPrivateComponent />}
        />

        {/* Default Not Found Page */}
        <Route
          path="*"
          element={<RouteWithLoaderPublic component={NotFoundPage} />}
        />
      </Routes>
    </AuthProvider>
  );
};


export default App;
