import React, {
  useContext,
  useState,
  useEffect,
} from 'react';

import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';

import app from './firebase';

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserToken, setCurrentUserToken] = useState(null);

  const auth = getAuth(app);

  const login = (email, password) => signInWithEmailAndPassword(auth, email, password);

  const logout = () => signOut(auth);

  const getToken = () => {
    if (currentUser) {
      currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
        return idToken;
      }).catch(function (error) {
        return null;
      });
    }
    return null;
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
      if (user) {
        user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
          setCurrentUserToken(idToken);
        }).catch(function (error) {
          setCurrentUserToken(null);
        });
      }
    })
  }, [auth])

  const value = {
    currentUser,
    currentUserToken,
    login,
    logout,
    getToken,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => (
  useContext(AuthContext)
);
