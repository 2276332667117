import React, { useState } from 'react'
import { Form, Row, Col, Button, Spinner } from '@themesberg/react-bootstrap';
import Select from 'react-select';
import { useSWRConfig } from 'swr'

import { useAuth } from '../../AuthContext';

import sports from '../../utils/sports';
import countries from '../../utils/countries.json';
import { createAgency } from '../../api/post';

const AddSoccerAthleteForm = (props) => {
  const { hideModal } = props;
  const { currentUser, getToken } = useAuth();
  const { mutate } = useSWRConfig()

  const [input, setInput] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // setLoading(true);
    // createAgency(currentUser.uid, input, getToken()).then(() => {
    //   mutate([`/api/users/${currentUser.uid}`]);
    //   mutate([`/api/users/${currentUser.uid}/profiles`]);
    //   hideModal()
    // }).catch((err) => {
    //   console.log(err);
    // }).finally(() => {
    //   setLoading(false);
    // })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col lg={6}>
          <p>Coming soon</p>
        </Col>
      </Row>

    </Form>
  )
}

export default AddSoccerAthleteForm;