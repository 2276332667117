import React from 'react';
import { Link } from "react-router-dom";
import { Card, Table } from '@themesberg/react-bootstrap';
import useSWR from 'swr';

import Skeleton from '../skeletonLoader/SkeletonLoader';

import countries from '../../utils/countries.json'
import fetcher from '../../utils/fetcher';

const NewAthletesCard = () => {
  const { data: athletes, error: errorAthletes } = useSWR(['/api/athletes/'], fetcher);

  return (
    <Card border="grey" className="border-3 shadow-sm mb-4">
      <Card.Header>
        <b>New Athletes</b>
      </Card.Header>
      <Card.Body className="p-0">
        {!athletes || errorAthletes ?
          <Skeleton />
          :
          <Table responsive>
            <thead className="thead-light">
              <tr>
                <th className="border-0">Name</th>
                <th className="border-0">Gender</th>
                <th className="border-0">Sport</th>
                <th className="border-0">Country of Residence</th>
              </tr>
            </thead>
            <tbody>
              {athletes.map((athlete) =>
                <tr key={athlete.id}>
                  <td>
                    <Link to={`/athletes/${athlete.id}`}>
                      <span className="fw-bold fs-6">{athlete.name}</span>
                    </Link>
                  </td>
                  <td>{athlete.gender || '-'}</td>
                  <td>{athlete.sport}</td>
                  <td>{countries[athlete.country_of_residence] || '-'}</td>
                </tr>
              )}
            </tbody>
          </Table>
        }
      </Card.Body>
    </Card>
  )
}

export default NewAthletesCard