import React, { useState } from 'react'
import { Form, Row, Col, Button, Spinner, InputGroup, Alert } from '@themesberg/react-bootstrap';
import Select from 'react-select';
import useSWR from 'swr';
import { useSWRConfig } from 'swr';
import fetcher from '../../utils/fetcher';

import { useAuth } from '../../AuthContext';

import sports from '../../utils/sports';
import countries from '../../utils/countries.json';
import countries2 from '../../utils/countries2.json';
import { createAgency, createAthlete } from '../../api/post';

const AddTennisAthleteForm = (props) => {
  const { hideModal } = props;
  const { currentUser, getToken, currentUserToken } = useAuth();

  const { mutate } = useSWRConfig()
  const { data: user, error: errorUser } = useSWR(currentUser && currentUserToken ? [`/api/users/me`, {}, currentUserToken] : null, fetcher);

  const [input, setInput] = useState({ sport: sports.TENNIS });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  if (!user || errorUser) {
    return null;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(input);
    createAthlete(currentUser.uid, user.active_profile, input, currentUserToken).then(() => {
      mutate([`/api/users/me/agencies/${user.active_profile}/athletes`, {}, currentUserToken]);
      hideModal()
    }).catch((err) => {
      setError(err.response.data.detail);
      console.log(err.response);
    }).finally(() => {
      setLoading(false);
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          {error &&
            <Alert variant="danger">
              {Array.isArray(error)
                ? error.map((err) => <span>{`${err.loc[1]}: ${err.msg}, `}</span>)
                : { error }
              }
            </Alert>}
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Name*</Form.Label>
            <Form.Control
              type="text"
              placeholder="John Doe"
              onChange={(event) => setInput((prevState) => {
                return {
                  ...prevState,
                  name: event.target.value,
                }
              })}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Available for*</Form.Label>
            <InputGroup>
              <Form.Select
                onChange={(event) => setInput((prevState) => {
                  return {
                    ...prevState,
                    available_semester: event.target.value,
                  }
                })}
              >
                <option value="">Select...</option>
                <option value="Fall">Fall</option>
                <option value="Spring">Spring</option>
              </Form.Select>
              <Form.Control
                className="ps-2"
                type="number"
                placeholder="Year"
                min={2022}
                max={2050}
                step={1}
                onChange={(event) => setInput((prevState) => {
                  return {
                    ...prevState,
                    available_year: event.target.value,
                  }
                })}
              />
            </InputGroup>
          </Form.Group>
        </Col>

      </Row>

      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Gender</Form.Label>
            <Form.Select
              onChange={(event) => setInput((prevState) => {
                return {
                  ...prevState,
                  gender: event.target.value,
                }
              })}
            >
              <option value="">Select...</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Country of Residence</Form.Label>
            <Form.Select
              onChange={(event) => setInput((prevState) => {
                return {
                  ...prevState,
                  country_of_residence: countries2[event.target.value],
                }
              })}
            >
              <option value="">Select...</option>
              {Object.entries(countries).map(([key, value]) => <option key={key}>{value}</option>)}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>GPA</Form.Label>
            <Form.Control
              type="number"
              placeholder="GPA"
              step={0.1}
              min={1}
              max={4}
              onChange={(event) => setInput((prevState) => {
                return {
                  ...prevState,
                  GPA: event.target.value,
                }
              })}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>SAT</Form.Label>
            <Form.Control
              type="number"
              placeholder="SAT Score"
              min={400}
              max={1600}
              step={1}
              onChange={(event) => setInput((prevState) => {
                return {
                  ...prevState,
                  SAT: event.target.value,
                }
              })}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>TOEFL</Form.Label>
            <Form.Control
              type="number"
              placeholder="TOEFL Score"
              min={0}
              max={120}
              step={1}
              onChange={(event) => setInput((prevState) => {
                return {
                  ...prevState,
                  TOEFL: event.target.value,
                }
              })}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>UTR</Form.Label>
            <Form.Control
              type="number"
              placeholder="UTR"
              min={1}
              max={16.50}
              step={0.01}
              onChange={(event) => setInput((prevState) => {
                return {
                  ...prevState,
                  UTR: event.target.value,
                }
              })}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>ITF</Form.Label>
            <Form.Control
              type="number"
              placeholder="ITF"
              min={1}
              step={1}
              onChange={(event) => setInput((prevState) => {
                return {
                  ...prevState,
                  ITF: event.target.value,
                }
              })}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Link to Profile</Form.Label>
            <Form.Control
              type="text"
              placeholder="https://agency.com/athlete-name"
              onChange={(event) => setInput((prevState) => {
                return {
                  ...prevState,
                  profile_url: event.target.value,
                }
              })}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Video</Form.Label>
            <Form.Control
              type="text"
              placeholder="https://youtube.com/examplevideo"
              onChange={(event) => setInput((prevState) => {
                return {
                  ...prevState,
                  video_1: event.target.value,
                }
              })}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Bio</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              placeholder="Example bio"
              onChange={(event) => setInput((prevState) => {
                return {
                  ...prevState,
                  bio: event.target.value,
                }
              })}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button
            className="w-100"
            variant="success"
            type="submit"
            disabled={loading}>
            {loading ?
              <Spinner animation="border" />
              :
              <span>Create</span>
            }
          </Button>
        </Col>
      </Row>

    </Form>
  )
}

export default AddTennisAthleteForm;