import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import Preloader from './Preloader';
import routes from '../routes';

import { useAuth } from '../AuthContext'

const RouteWithLoaderPrivate = ({ component: Component }) => {
  const { currentUser } = useAuth();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  if (currentUser) {
    return (
      <Navigate to={routes.HomePage.path} />
    );
  };

  return (
    <>
      <Preloader show={!loaded} />
      <Component />
    </>
  );
};

export default RouteWithLoaderPrivate;
