import React, { useState, useEffect } from 'react';
import { Col, Row, Card, Breadcrumb, Button, Form } from '@themesberg/react-bootstrap';
import Select from 'react-select';

import Content from '../Content';
import { useAuth } from '../../AuthContext'

import useSWR from 'swr';
import sports from '../../utils/sports';
import countries from '../../utils/countries.json';
import fetcher from '../../utils/fetcher';

const MyAgencyPage = () => {
  const { currentUser, currentUserToken } = useAuth();

  const { data: user, error: errorUser } = useSWR(currentUser && currentUserToken ? [`/api/users/me`, {}, currentUserToken] : null, fetcher);
  const { data: profile, error: errorProfile } = useSWR(currentUser && user && currentUserToken ? [`/api/users/me/agencies/${user.active_profile}`, {}, currentUserToken] : null, fetcher);

  const [input, setInput] = useState();

  useEffect(() => {
    if (profile) {
      setInput({
        name: profile.name,
        sports: profile.sports,
        address: profile.address,
        city: profile.city,
        zip_code: profile.zip_code,
        country: profile.country,
        email: profile.email,
        phone_number: profile.phone_number,
        agency_information: profile.agency_information,
      })
    }
  }, [profile]);

  if (!user || errorUser || !profile || errorProfile || !input) {
    return null;
  }

  return (
    <Content>
      <Breadcrumb listProps={{ className: "breadcrumb-primary breadcrumb-transparent" }}>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="#" active>My Agency</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col xl>
          <Card border="grey" className="border-3 shadow-sm mb-4">
            <Card.Header>
              <b>My Agency</b>
            </Card.Header>
            <Card.Body className="pb-0">
              <Form>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Agency Name*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="My Agency"
                        defaultValue={input.name}
                        onChange={(event) => setInput((prevState) => {
                          return {
                            ...prevState,
                            name: event.target.value,
                          }
                        })}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Supported Sports*</Form.Label>
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        options={Object.entries(sports).map(([_, value]) => ({
                          value: value,
                          label: value,
                        }))}
                        defaultValue={input.sports.map((value) => ({
                          value: value,
                          label: value,
                        }))}
                        onChange={(event) => {
                          setInput((prevState) => {
                            return {
                              ...prevState,
                              sports: event.map((sport) => sport.value),
                            }
                          })
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Adress</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Address 1"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="City"
                        defaultValue={input.city || ""}
                        onChange={(event) => setInput((prevState) => {
                          return {
                            ...prevState,
                            city: event.target.value,
                          }
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>

                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Zip Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="1234AB"
                        defaultValue={input.zip_code || ""}
                        onChange={(event) => setInput((prevState) => {
                          return {
                            ...prevState,
                            zip_code: event.target.value,
                          }
                        })}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Country</Form.Label>
                      <Form.Select
                        value={input.country || ""}
                        onChange={(event) => setInput((prevState) => {
                          return {
                            ...prevState,
                            country: event.target.value,
                          }
                        })}
                      >
                        <option value="">Select...</option>
                        {Object.entries(countries).map(([key, value]) => <option key={key} value={key}>{value}</option>)}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="myagency@example.com"
                        defaultValue={input.email || ''}
                        onChange={(event) => setInput((prevState) => {
                          return {
                            ...prevState,
                            email: event.target.value,
                          }
                        })}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="+31-12345678"
                        defaultValue={input.phone_number || ''}
                        onChange={(event) => setInput((prevState) => {
                          return {
                            ...prevState,
                            phone_number: event.target.value,
                          }
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg>
                    <Form.Group className="mb-3">
                      <Form.Label>Agency Information</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        placeholder="Example information for my agency"
                        defaultValue={input.agency_information || ''}
                        onChange={(event) => setInput((prevState) => {
                          return {
                            ...prevState,
                            agency_information: event.target.value,
                          }
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>

              </Form>
            </Card.Body>
            <Card.Footer>
              <Button
                variant="success"
                className="mb-3 float-end"
                type='submit'
              >
                Save
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Content>
  );
};

export default MyAgencyPage;
