import React from 'react';
import { Link } from "react-router-dom";
import { Card, Table } from '@themesberg/react-bootstrap';
import useSWR from 'swr';

import Skeleton from '../skeletonLoader/SkeletonLoader';

import countries from '../../utils/countries.json'
import fetcher from '../../utils/fetcher';

const AgenciesCard = () => {
  const { data: agencies, error: errorAgencies } = useSWR(['/api/agencies/'], fetcher);

  return (
    <Card border="grey" className="border-3 shadow-sm mb-4">
      <Card.Header>
        <b>Agencies</b>
      </Card.Header>
      <Card.Body className="p-0">
        {!agencies || errorAgencies ?
          <Skeleton />
          :
          <Table responsive>
            <thead className="thead-light">
              <tr>
                <th className="border-0">Name</th>
                <th className="border-0">Sports</th>
                <th className="border-0">Location</th>
              </tr>
            </thead>
            <tbody>
              {agencies.map((agency) =>
                <tr key={agency.id}>
                  <td>
                    <Link to={`/agencies/${agency.id}`}>
                      <span className="fw-bold fs-6">{agency.name}</span>
                    </Link>
                  </td>
                  <td>{agency.sports || '-'}</td>
                  {/* <td>{agency.sports ? agency.sports.map((sport) => `${sport}, `) : '-'}</td> */}
                  <td>{countries[agency.country] || '-'}</td>
                </tr>
              )}
            </tbody>
          </Table>
        }
      </Card.Body>
    </Card>
  )
}

export default AgenciesCard