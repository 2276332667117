import React from 'react';

const PropertyLabelExternalLink = (props) => {
  const { title, value, link } = props;

  return (
    <div className="d-flex flex-column mb-2">
      <span className="fw-bold">{`${title}:`}</span>
      <a href={link}>{value}</a>
    </div>
  )
}

export default PropertyLabelExternalLink;