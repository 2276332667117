import React, { useState } from 'react';
import { Col, Row, Card, Breadcrumb, Table, Button } from '@themesberg/react-bootstrap';

import Content from '../Content';

import { useAuth } from '../../AuthContext'
import useSWR from 'swr';
import useModal from '../../hooks/useModal';

import fetcher from '../../utils/fetcher';
import AddAthleteModal from './AddAthleteModal';

const MyAthletesPage = () => {
  const { currentUser, currentUserToken } = useAuth();

  const { data: user, error: errorUser } = useSWR(currentUser && currentUserToken ? [`/api/users/me`, {}, currentUserToken] : null, fetcher);
  const { data: athletes, error: errorAthletes } = useSWR(currentUser && user && currentUserToken ? [`/api/users/me/agencies/${user.active_profile}/athletes`, {}, currentUserToken] : null, fetcher);

  const { isShowing: isShowingAddAthleteModal, toggle: toggleAddAthleteModal } = useModal();

  if (!user || errorUser || !athletes || errorAthletes) {
    return null;
  }

  return (
    <Content>
      <Breadcrumb listProps={{ className: "breadcrumb-primary breadcrumb-transparent" }}>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="#" active>My Athletes</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col xl>
          <Card border="grey" className="border-3 shadow-sm mb-4">
            <Card.Header className="d-flex justify-content-between">
              <b>My Athletes</b>
              <Button className="my-0 py-0" variant="success" size="sm" onClick={toggleAddAthleteModal}>Add</Button>
            </Card.Header>
            <Card.Body className="p-0">
              <Table responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="border-0">Name</th>
                    {/* <th className="border-0">Gender</th> */}
                    <th className="border-0">Sport</th>
                    {/* <th className="border-0">Available</th>
                    <th className="border-0">Agency</th>
                    <th className="border-0">GPA</th>
                    <th className="border-0">SAT</th>
                    <th className="border-0">TOEFL</th> */}
                  </tr>
                </thead>
                <tbody>
                  {athletes.map((athlete) =>
                    <tr key={athlete.id}>
                      <td>
                        <a href="#Unites States" className="fw-bold fs-6">
                          {athlete.name}
                        </a>
                      </td>
                      <td>{athlete.sport}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddAthleteModal
        isShowing={isShowingAddAthleteModal}
        hide={toggleAddAthleteModal}
      />
    </Content>
  );
};

export default MyAthletesPage;
