import React, { useState } from 'react';
import { Col, Row, Card, Table, Breadcrumb, Form } from '@themesberg/react-bootstrap';
import { useParams } from "react-router-dom";

import PropertyLabel from '../PropertyLabel';
import PropertyLabelLink from '../PropertyLabelLink';
import PropertyLabelExternalLink from '../PropertyLabelExternalLink';

import SkeletonLoader from '../skeletonLoader/SkeletonLoader';

import Content from '../Content';

import useSWR from 'swr';
import countries from '../../utils/countries.json'

import fetcher from '../../utils/fetcher';

const AthleteDetailsPage = () => {
  const { athleteId } = useParams();
  const { data: athlete, error: errorAthlete } = useSWR([`/api/athletes/${athleteId}`], fetcher);

  console.log(athlete);

  return (
    <Content>
      <div className="d-flex justify-content-between" >
        <Breadcrumb listProps={{ className: "breadcrumb-primary breadcrumb-transparent" }}>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/athletes">Athletes</Breadcrumb.Item>
          <Breadcrumb.Item href="#" active>Details</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <Row>
        <Col xl={7}>
          <Card border="grey" className="border-3 shadow-sm mb-4">
            <Card.Header>
              <b>Personal</b>
            </Card.Header>
            <Card.Body className="pb-0">
              {!athlete || errorAthlete ?
                <SkeletonLoader />
                :
                <>
                  <PropertyLabel title="Name" value={athlete.name} />
                  <PropertyLabel title="Gender" value={athlete.gender || '-'} />
                  <PropertyLabel title="Country of Residence" value={countries[athlete.country_of_residence] || '-'} />
                  <PropertyLabel title="Bio" value={athlete.bio || '-'} />
                </>
              }
            </Card.Body>
          </Card>

          <Card border="grey" className="border-3 shadow-sm mb-4">
            <Card.Header>
              <b>Agency and video</b>
            </Card.Header>
            <Card.Body className="pb-0">
              {!athlete || errorAthlete ?
                <SkeletonLoader />
                :
                <>
                  <PropertyLabelLink title="Agency" value={athlete.agency_name || '-'} link={`/agencies/${athlete.agency_id}`} />
                  <PropertyLabelExternalLink title="External profile" value={athlete.profile_url || '-'} link={athlete.profile_url || undefined} />
                  <PropertyLabelExternalLink title="Video" value={athlete.video_1 || '-'} link={athlete.profile_url || undefined} />
                </>
              }
            </Card.Body>
          </Card>
        </Col>

        <Col xl={5}>
          <Card border="grey" className="border-3 shadow-sm mb-4">
            <Card.Header>
              <b>Athletic profile</b>
            </Card.Header>
            <Card.Body className="pb-0">
              {!athlete || errorAthlete ?
                <SkeletonLoader />
                :
                <>
                  <PropertyLabel title="Sport" value={athlete.sport} />
                  <PropertyLabel title="Available for" value={athlete.available_semester && athlete.available_year ? `${athlete.available_semester} ${athlete.available_year} ` : '-'} />
                  <PropertyLabel title="UTR" value={athlete.UTR || '-'} />
                  <PropertyLabel title="ITF" value={athlete.ITF || '-'} />
                </>
              }
            </Card.Body>
          </Card>

          <Card border="grey" className="border-3 shadow-sm mb-4">
            <Card.Header>
              <b>Educational profile</b>
            </Card.Header>
            <Card.Body className="pb-0">
              {!athlete || errorAthlete ?
                <SkeletonLoader />
                :
                <>
                  <PropertyLabel title="GPA" value={athlete.GPA || '-'} />
                  <PropertyLabel title="SAT" value={athlete.SAT || '-'} />
                  <PropertyLabel title="TOEFL" value={athlete.TOEFL || '-'} />
                </>
              }
            </Card.Body>
          </Card>

        </Col>


      </Row>


      {/* <Row>
        <Col xl={7}>
          <Card border="grey" className="border-3 shadow-sm mb-4">
            <Card.Header>
              <b>SPORT</b>
            </Card.Header>
            <Card.Body className="pb-0">
            </Card.Body>
          </Card>
        </Col>

        <Col xl={5}>
          <Card border="grey" className="border-3 shadow-sm mb-4">
            <Card.Header>
              <b>GRADES</b>
            </Card.Header>
            <Card.Body className="pb-0">
            </Card.Body>
          </Card>
        </Col>

      </Row> */}

    </Content>
  );
};

export default AthleteDetailsPage;
