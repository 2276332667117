import React from 'react';
import {
  Nav,
  Image,
  Navbar as NavBarBootstrap,
  Dropdown,
  Container,
  Button,
  ButtonGroup,
} from '@themesberg/react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faCog,
  faSignOutAlt,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';

// import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
// import axios from 'axios';
import { Link } from 'react-router-dom';
// import { apiURL } from '../api/api';

import profile from './assets/profile-picture.jpg';

import { useAuth, getToken } from '../AuthContext'
import useSWR from 'swr';
import fetcher from '../utils/fetcher';


import routes from '../routes';
// import { Loader } from './Loader';

// const Notification = (props) => {
//   const {
//     text,
//     title,
//   } = props;

//   return (
//     <ListGroup.Item action href="#" className="border-bottom border-light">
//       <Row className="align-items-center">
//         <Col className="ps-2 ms--2">
//           <div className="d-flex justify-content-between align-items-center">
//             <div>
//               <h4 className="h6 mb-0 text-small">{title}</h4>
//             </div>
//             {/* <div className="text-end">
//               <small>{timestamp}</small>
//             </div> */}
//           </div>
//           <p className="font-small mt-1 mb-0">{text}</p>
//         </Col>
//       </Row>
//     </ListGroup.Item>
//   );
// };

const Profiles = (props) => {
  const { profiles, errorProfiles } = props;

  if (!profiles || errorProfiles) {
    return (
      <Dropdown.Item className="fw-bold" as={Link} to={routes.AccountPage.path}>Create new profile</Dropdown.Item>
    )
  }

  return (
    <>
      {profiles.map((profile) =>
        <Dropdown.Item
          className="fw-bold"
          as={Link}
          to={routes.AccountPage.path}
          key={profile.id}
        >
          {profile.name}
        </Dropdown.Item>
      )}
      < Dropdown.Divider />
      <Dropdown.Item className="fw-bold" as={Link} to={routes.AccountPage.path}>Create new profile</Dropdown.Item>
    </>
  )
}

const ProfilesDropdown = () => {
  const { currentUser, currentUserToken } = useAuth();

  const { data: user, error: errorUser } = useSWR(currentUser && currentUserToken ? [`/api/users/me`, {}, currentUserToken] : null, fetcher);
  const { data: profiles, error: errorProfiles } = useSWR(currentUser && currentUserToken ? [`/api/users/me/profiles`, {}, currentUserToken] : null, fetcher);

  if (!currentUser) {
    return null;
  }

  return (
    // <Dropdown size="sm" drop="down" as={ButtonGroup} >
    <Dropdown drop="down" as={ButtonGroup} >
      <Button variant="outline-primary">
        <div className="text-truncate" style={{ 'maxWidth': '150px' }}>
          {user?.active_profile_name ?
            `${user.active_profile_name}`
            :
            'My Profile'
          }
        </div>
      </Button>
      <Dropdown.Toggle split variant="outline-primary">
        <FontAwesomeIcon icon={faAngleDown} className="dropdown-arrow" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Profiles profiles={profiles} errorProfiles={errorProfiles} />
      </Dropdown.Menu>
    </Dropdown>
  )
}

const Navbar = () => {
  const { currentUser, logout } = useAuth();

  const handleLogout = async (e) => {
    e.preventDefault();
    logout();
  };

  return (
    <NavBarBootstrap variant="dark" expanded className="px-2 pb-0">
      <Container fluid className="px-0">
        <div className="w-100">
          <Nav className="d-flex justify-content-between align-items-center ms-auto">
            <div>
              <ProfilesDropdown />
            </div>

            {/* <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" />
                  <span className="icon-badge rounded-circle unread-notifications" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="border-dark dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0"> */}
            {/* <ListGroup className="list-group-flush">
                  <Nav.Link href="#" className="text-center text-primary
                  fw-bold border-bottom border-light py-3">
                    Notifications
                  </Nav.Link>
                  {notificationsLoaded ?
                    <>
                      {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)}
                      <Dropdown.Item className="text-center text-primary fw-bold py-3">
                        View all
                      </Dropdown.Item>
                    </>
                    :
                    <Loader />
                  }
                </ListGroup> */}
            {/* </Dropdown.Menu>
            </Dropdown> */}

            {currentUser ?
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link}>
                  <div className="media d-flex align-items-center">
                    <Image src={profile} className="user-avatar md-avatar rounded-circle" />
                    <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                      <span className="font-small fw-bold">{currentUser.email}</span>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right">
                  <Dropdown.Item className="fw-bold" as={Link} to={routes.AccountPage.path}>
                    <FontAwesomeIcon icon={faUserCircle} className="me-2" />
                    My Account
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold" as={Link} to={routes.SettingsPage.path}>
                    <FontAwesomeIcon icon={faCog} className="me-2" />
                    Settings
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item className="fw-bold" onClick={handleLogout}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              :
              <Button
                variant="tertiary"
                as={Link}
                to={routes.SigninPage.path}
              >
                Sign up / Login
              </Button>
            }
          </Nav>
        </div>
      </Container>
    </NavBarBootstrap>
  );
};

export default Navbar;
