const routes = {
  // Error Pages
  NotFoundPage: { path: '/404' },
  ServerErrorPage: { path: '/500' },

  // Dashboard Pages
  HomePage: { path: '/' },

  AthletesPage: { path: '/athletes' },
  AthleteDetailsPage: { path: '/athletes/:athleteId' },

  AgenciesPage: { path: '/agencies' },
  AgencyDetailsPage: { path: '/agencies/:agencyId' },

  CoachesPage: { path: '/coaches' },
  CoachDetailsPage: { path: '/coaches/:coachId' },

  AccountPage: { path: '/account' },

  MyAthletesPage: { path: '/my-athletes' },
  MyAgencyPage: { path: '/my-agency' },

  AddPage: { path: '/add' }, // TODO: to be deleted
  AddAgencyPage: { path: '/add-agency' }, // TODO: to be deleted
  SettingsPage: { path: '/settings' },

  // Auth Pages
  SigninPage: { path: '/sign-in' },
  SignupPage: { path: '/sign-up' },
  ForgotPasswordPage: { path: '/forgot-password' },
  ResetPasswordPage: { path: '/reset-password' },
};

export default routes;
