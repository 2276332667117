import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Col, Row, Card, Table, Breadcrumb } from '@themesberg/react-bootstrap';

import Skeleton from '../skeletonLoader/SkeletonLoader';

import useSWR from 'swr';
import fetcher from '../../utils/fetcher';

const AthletesCard = () => {
  const [queryParams, setQueryParams] = useState({})

  const { data: athletes, error: errorAthletes } = useSWR(['/api/athletes/', queryParams], fetcher);

  return (
    <Card border="grey" className="border-3 shadow-sm mb-4">
      <Card.Header>
        <b>Athletes</b>
      </Card.Header>
      <Card.Body className="p-0">
        {!athletes || errorAthletes ?
          <Skeleton />
          :
          <Table responsive>
            <thead className="thead-light">
              <tr>
                <th className="border-0">Name</th>
                <th className="border-0">Gender</th>
                <th className="border-0">Sport</th>
                <th className="border-0">Available for</th>
                <th className="border-0">Agency</th>
                <th className="border-0">GPA</th>
                <th className="border-0">SAT</th>
                <th className="border-0">TOEFL</th>
              </tr>
            </thead>
            <tbody>
              {athletes.map((athlete) =>
                <tr key={athlete.id}>
                  <td>
                    <Link to={`/athletes/${athlete.id}`}>
                      <span className="fw-bold fs-6">{athlete.name}</span>
                    </Link>
                  </td>
                  <td>{athlete.gender || '-'}</td>
                  <td>{athlete.sport || '-'}</td>
                  <td>{athlete.available_semester && athlete.available_year ? `${athlete.available_semester} ${athlete.available_year} ` : '-'}</td>
                  <td>
                    <Link to={`/agencies/${athlete.agency_id}`}>
                      <span className="fw-bold fs-6">{athlete.agency_name || '-'}</span>
                    </Link>
                  </td>
                  <td>{athlete.GPA || '-'}</td>
                  <td>{athlete.SAT || '-'}</td>
                  <td>{athlete.TOEFL || '-'}</td>
                </tr>
              )}
            </tbody>
          </Table>
        }

      </Card.Body>
    </Card>
  )
}

export default AthletesCard