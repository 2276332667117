import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyCuy6HST1vFECP5aK4C-OXuDNDCx_0Q868",
  authDomain: "compete-in-college-test.firebaseapp.com",
  projectId: "compete-in-college-test",
  storageBucket: "compete-in-college-test.appspot.com",
  messagingSenderId: "1009405115789",
  appId: "1:1009405115789:web:f9b08323f186940f4351c4"
};

// Initialize Firebase and Firebase Authentication
const app = initializeApp(firebaseConfig)

export default app;