import React, { useState } from 'react';
import { Col, Row, Card, Breadcrumb, Table } from '@themesberg/react-bootstrap';

import Content from '../Content';

import useSWR from 'swr';
import { Link } from "react-router-dom";

import fetcher from '../../utils/fetcher';
import countries from '../../utils/countries.json'

import Skeleton from '../skeletonLoader/SkeletonLoader';

const AgenciesPage = () => {
  const [queryParams, setQueryParams] = useState({})

  const { data: agencies, error: errorAgencies } = useSWR(['/api/agencies/', queryParams], fetcher);

  if (!agencies || errorAgencies) {
    return null;
  }

  return (
    <Content>
      <Breadcrumb listProps={{ className: "breadcrumb-primary breadcrumb-transparent" }}>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="#" active>Agencies</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col xl>
          <Card border="grey" className="border-3 shadow-sm mb-4">
            <Card.Header>
              <b>Agencies</b>
            </Card.Header>
            <Card.Body className="p-0">
              {!agencies || errorAgencies ?
                <Skeleton />
                :
                <Table responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="border-0">Name</th>
                      <th className="border-0">Sports</th>
                      <th className="border-0">Country</th>
                    </tr>
                  </thead>
                  <tbody>
                    {agencies.map((agency) =>
                      <tr key={agency.id}>
                        <td>
                          <Link to={`/agencies/${agency.id}`}>
                            <span className="fw-bold fs-6">{agency.name}</span>
                          </Link>
                        </td>
                        <td>{agency.sports || '-'}</td>
                        <td>{countries[agency.country] || '-'}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              }

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Content>
  );
};

export default AgenciesPage;
