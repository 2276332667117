import React, { useState } from 'react';
import {
  Col,
  Row,
  Card,
  Form,
  InputGroup,
  Button,
} from '@themesberg/react-bootstrap';

import Content from '../Content';

import useSWR from 'swr';

import countries from '../../utils/countries.json'
import sports from '../../utils/sports';
import fetcher from '../../utils/fetcher';

const AddAgencyPage = () => {
  const { data: agencies, error: errorAgencies } = useSWR(['/api/agencies/'], fetcher);

  const [input, setInput] = useState({});
  const [password, setPassword] = useState('');

  if (!agencies || errorAgencies) {
    return null;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // axios.post(
    //   '/api/athletes',
    //   input,
    //   {
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Password': password,
    //     }
    //   },
    // ).then((response) => {
    //   console.log(response.data);
    // }).catch((error) => {
    //   console.log(error.response.data);
    // });
  }

  return (
    <Content>
      <Row>
        <Col>
          <Card border="grey" className="border-3 shadow-sm mb-4">
            <Form onSubmit={handleSubmit}>
              <Card.Header>
                <b>ADD AGENCY</b>
              </Card.Header>
              <Card.Body className="pb-0">
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Name*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="John Doe"
                        onChange={(event) => setInput((prevState) => {
                          return {
                            ...prevState,
                            name: event.target.value,
                          }
                        })}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Gender*</Form.Label>
                      <Form.Select
                        onChange={(event) => setInput((prevState) => {
                          return {
                            ...prevState,
                            gender: event.target.value,
                          }
                        })}
                      >
                        <option value="">Select...</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Country</Form.Label>
                      <Form.Select
                        onChange={(event) => setInput((prevState) => {
                          return {
                            ...prevState,
                            coutry_of_residence: event.target.value,
                          }
                        })}
                      >
                        <option value="">Select...</option>
                        {Object.entries(countries).map(([key, value]) => <option key={key}>{value}</option>)}
                        {/* {countries.map((country) =>
                          <option
                            key={country.countryCode}
                            value={country.countryCode}>
                            {country.country}
                          </option>)} */}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Availability*</Form.Label>
                      <InputGroup>
                        <Form.Select
                          onChange={(event) => setInput((prevState) => {
                            return {
                              ...prevState,
                              available_semester: event.target.value,
                            }
                          })}
                        >
                          <option value="">Select...</option>
                          <option value="Fall">Fall</option>
                          <option value="Spring">Spring</option>
                        </Form.Select>
                        <Form.Control
                          className="ps-2"
                          type="number"
                          placeholder="Year"
                          onChange={(event) => setInput((prevState) => {
                            return {
                              ...prevState,
                              available_year: event.target.value,
                            }
                          })}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Agency*</Form.Label>
                      <Form.Select
                        onChange={(event) => setInput((prevState) => {
                          return {
                            ...prevState,
                            agency_id: event.target.value,
                          }
                        })}
                      >
                        <option value="">Select...</option>
                        {agencies.map((agency) =>
                          <option
                            key={agency.id}
                            value={agency.id}>
                            {agency.name}
                          </option>)}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Agency profile link</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="https://agency.com/athlete-name"
                        onChange={(event) => setInput((prevState) => {
                          return {
                            ...prevState,
                            agency_profile: event.target.value,
                          }
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>GPA</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="GPA"
                        onChange={(event) => setInput((prevState) => {
                          return {
                            ...prevState,
                            GPA: event.target.value,
                          }
                        })}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>SAT</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="SAT Score"
                        onChange={(event) => setInput((prevState) => {
                          return {
                            ...prevState,
                            SAT: event.target.value,
                          }
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>TOEFL</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="TOEFL Score"
                        onChange={(event) => setInput((prevState) => {
                          return {
                            ...prevState,
                            TOEFL: event.target.value,
                          }
                        })}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Sport*</Form.Label>
                      <Form.Select
                        onChange={(event) => setInput((prevState) => {
                          return {
                            ...prevState,
                            sport: event.target.value,
                          }
                        })}
                      >
                        <option value="">Select...</option>
                        {Object.entries(sports).map(([_, value]) => <option key={value}>{value}</option>)}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Video</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="https://youtube.com/examplevideo"
                        onChange={(event) => setInput((prevState) => {
                          return {
                            ...prevState,
                            video1: event.target.value,
                          }
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Bio</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        placeholder="Example bio"
                        onChange={(event) => setInput((prevState) => {
                          return {
                            ...prevState,
                            bio: event.target.value,
                          }
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {input?.sport === sports.TENNIS &&
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>UTR</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="UTR"
                          onChange={(event) => setInput((prevState) => {
                            return {
                              ...prevState,
                              UTR: event.target.value,
                            }
                          })}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                }

                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Password"
                        onChange={(event) => setPassword(event.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

              </Card.Body>
              <Card.Footer>
                <Button
                  variant="success"
                  className="mb-3 float-end"
                  type='submit'
                >
                  Submit
                </Button>
              </Card.Footer>
            </Form>
          </Card>
        </Col>
      </Row>
    </Content >
  );
};

export default AddAgencyPage;
