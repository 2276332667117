import axios from 'axios';

const fetcher = async (url, queryParams = {}, token = null) => {
  if (token) {
    const res = await axios.get(
      url,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        params: queryParams,
      },
    );
    return res.data;
  }
  const res = await axios.get(
    url,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      params: queryParams,
    },
  );
  return res.data;
};

export default fetcher;