import React from 'react';
import { Col, Row, Breadcrumb } from '@themesberg/react-bootstrap';

import Content from '../Content';
import AthletesCard from './AthletesCard';

const AthletesPage = () => {
  return (
    <Content>
      <Breadcrumb listProps={{ className: "breadcrumb-primary breadcrumb-transparent" }}>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="#" active>Athletes</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col xl>
          <AthletesCard />
        </Col>
      </Row>
    </Content>
  );
};

export default AthletesPage;
