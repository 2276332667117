import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

// Components
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';
import Preloader from './Preloader';

import { useAuth } from "../AuthContext"
import routes from '../routes';

const RouteWithSidebar = ({ component: Component, isPrivateComponent }) => {
  const { currentUser } = useAuth()
  console.log(currentUser);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  if (!currentUser && isPrivateComponent) {
    return (
      <Navigate to={routes.HomePage.path} />
    )
  }

  return (
    <>
      <Preloader show={!loaded} />
      <Sidebar />
      <main className="content">
        <Navbar />
        <Component />
        <Footer />
      </main>
    </>
  );
};

export default RouteWithSidebar;
