import React from 'react'

import Skeleton from 'react-loading-skeleton';

const SkeletonLoader = () => {
  return (
    <div className="px-2 py-1">
      <Skeleton count={5} />
    </div>
  )
}

export default SkeletonLoader;