import React from 'react';
import { Col, Row, Breadcrumb } from '@themesberg/react-bootstrap';

import Content from '../Content';

import NewAthletesCard from './NewAthletesCard';
import AgenciesCard from './AgenciesCard';

import Loader from '../Loader';

const HomePage = () => {
  return (
    <Content>
      <Breadcrumb listProps={{ className: "breadcrumb-primary breadcrumb-transparent" }}>
        <Breadcrumb.Item href="#home" active>Home</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col xl={6}>
          <NewAthletesCard />
        </Col>

        <Col xl={6}>
          <AgenciesCard />
        </Col>
      </Row>
    </Content>
  );
};

export default HomePage;
