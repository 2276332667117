import React from 'react'
// import { Form, Row, Col } from '@themesberg/react-bootstrap';
// import Select from 'react-select';

// import sports from '../../utils/sports';
// import countries from '../../utils/countries.json';

const AddCoachForm = () => {
  return (
    <p>Coming soon!</p>
  )
}

export default AddCoachForm;