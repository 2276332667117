import React, { useState } from 'react';
// import { Navigate } from 'react-router-dom';
import { Col, Row, Card, Breadcrumb, Table, Button } from '@themesberg/react-bootstrap';

import Content from '../Content';

import useSWR from 'swr';
import { useAuth } from '../../AuthContext';

import fetcher from '../../utils/fetcher';

import useModal from '../../hooks/useModal';

import AddProfileModal from './AddProfileModal';

// import routes from '../../routes';

const AccountPage = () => {
  const { currentUser, currentUserToken } = useAuth();

  const { data: user, error: errorUser } = useSWR(currentUser && currentUserToken ? [`/api/users/me`, {}, currentUserToken] : null, fetcher);
  const { data: profiles, error: errorProfiles } = useSWR(currentUser && currentUserToken ? [`/api/users/me/profiles`, {}, currentUserToken] : null, fetcher);

  const { isShowing: isShowingAddProfileModal, toggle: toggleAddProfileModal } = useModal();

  // if (!currentUser) {
  //   return (
  //     <Navigate to={routes.HomePage.path} />
  //   );
  // };

  if (!user || errorUser || !profiles || errorProfiles) {
    return null;
  }

  return (
    <Content>
      <Breadcrumb listProps={{ className: "breadcrumb-primary breadcrumb-transparent" }}>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/account" active>My Account</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col xl={6}>
          <Card border="grey" className="border-3 shadow-sm mb-4">
            <Card.Header className="d-flex justify-content-between">
              <b>Account Details</b>
            </Card.Header>
            <Card.Body className="pb-0">
              <p>Email: {user.email}</p>
              <p>Password: ******</p>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={6}>
          <Card border="grey" className="border-3 shadow-sm mb-4">
            <Card.Header className="d-flex justify-content-between">
              <b>My Profiles</b>
              <Button className="my-0 py-0" variant="success" size="sm" onClick={toggleAddProfileModal}>Add</Button>
            </Card.Header>
            <Card.Body className="p-0">
              <Table responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="border-0">Name</th>
                    <th className="border-0">Type</th>
                    <th className="border-0"></th>
                  </tr>
                </thead>
                <tbody>
                  {profiles.map((profile) =>
                    <tr key={profile.id}>
                      <td>
                        <a href="#Unites States" className="fw-bold">
                          {profile.name}
                        </a>
                      </td>
                      <td>{profile.profile_type}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddProfileModal
        isShowing={isShowingAddProfileModal}
        hide={toggleAddProfileModal}
      />
    </Content>
  );
};

export default AccountPage;
