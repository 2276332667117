import React from 'react';

const PropertyLabel = (props) => {
  const { title, value } = props;
  return (
    <div className="d-flex flex-column mb-2">
      <span className="fw-bold">{`${title}:`}</span>
      <span>{value}</span>
    </div>
  )
}

export default PropertyLabel;