import React from 'react';
import { Col, Row, Card, Breadcrumb } from '@themesberg/react-bootstrap';
import { useParams } from "react-router-dom";

import PropertyLabel from '../PropertyLabel';
import SkeletonLoader from '../skeletonLoader/SkeletonLoader';

import Content from '../Content';

import useSWR from 'swr';
import countries from '../../utils/countries.json'
import fetcher from '../../utils/fetcher';

const AgencyDetailsPage = () => {
  const { agencyId } = useParams();
  const { data: agency, error: errorAgency } = useSWR([`/api/agencies/${agencyId}`], fetcher);

  return (
    <Content>
      <Breadcrumb listProps={{ className: "breadcrumb-primary breadcrumb-transparent" }}>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/agencies">Agencies</Breadcrumb.Item>
        <Breadcrumb.Item href="#" active>Details</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col xl={7}>
          <Card border="grey" className="border-3 shadow-sm mb-4">
            <Card.Header>
              <b>General</b>
            </Card.Header>
            <Card.Body className="pb-0">
              {!agency || errorAgency ?
                <SkeletonLoader />
                :
                <>
                  <PropertyLabel title="Name" value={agency.name} />
                  <PropertyLabel title="Address" value={agency.address || '-'} />
                  <PropertyLabel title="City" value={agency.city || '-'} />
                  <PropertyLabel title="Zip code" value={agency.zip_code || '-'} />
                  <PropertyLabel title="Country" value={countries[agency.country] || '-'} />
                  <PropertyLabel title="Sports" value={agency.sports || '-'} />
                  <PropertyLabel title="Information" value={agency.agency_information || '-'} />
                </>}
            </Card.Body>
          </Card>
        </Col>

        <Col xl={5}>
          <Card border="grey" className="border-3 shadow-sm mb-4">
            <Card.Header>
              <b>Contact</b>
            </Card.Header>
            <Card.Body className="pb-0">
              {!agency || errorAgency ?
                <SkeletonLoader />
                :
                <>
                  <PropertyLabel title="Email" value={agency.email || '-'} />
                  <PropertyLabel title="Phone" value={agency.phone_number || '-'} />
                </>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Content>
  );
};

export default AgencyDetailsPage;
