import React from 'react';

const Content = ({ children }) => {
  return (
    // <div className="d-xl-flex justify-content-between
    // flex-wrap flex-md-nowrap align-items-center py-1">
    <div className="py-1 px-2">
      {children}
    </div>
  );
};

export default Content;
