import React, { useState } from 'react'
import { Form, Row, Col, Button, Spinner, Alert } from '@themesberg/react-bootstrap';
import Select from 'react-select';
import { useSWRConfig } from 'swr'

import { useAuth } from '../../AuthContext';

import sports from '../../utils/sports';
import countries from '../../utils/countries.json';
import { createAgency } from '../../api/post';

const AddAgencyForm = (props) => {
  const { hideModal } = props;
  const { currentUser, getToken, currentUserToken } = useAuth();
  const { mutate } = useSWRConfig()

  const [input, setInput] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    createAgency(currentUser.uid, input, currentUserToken).then(() => {
      mutate([`/api/users/me`, {}, currentUserToken]);
      mutate([`/api/users/me/profiles`, {}, currentUserToken]);
      hideModal()
    }).catch((err) => {
      setError(err.response.data.detail);
      console.log(err.response.data.detail);
    }).finally(() => {
      setLoading(false);
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          {error &&
            <Alert variant="danger">
              {Array.isArray(error)
                ? error.map((err) => <span>{`${err.loc[1]}: ${err.msg}, `}</span>)
                : { error }
              }
            </Alert>}
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Agency Name*</Form.Label>
            <Form.Control
              type="text"
              placeholder="My Agency"
              onChange={(event) => setInput((prevState) => {
                return {
                  ...prevState,
                  name: event.target.value,
                }
              })}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Supported Sports*</Form.Label>
            <Select
              isMulti
              closeMenuOnSelect={false}
              options={Object.entries(sports).map(([_, value]) => ({
                value: value,
                label: value,
              }))}
              onChange={(event) => {
                setInput((prevState) => {
                  return {
                    ...prevState,
                    sports: event.map((sport) => sport.value),
                  }
                })
              }}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Adress</Form.Label>
            <Form.Control
              type="text"
              placeholder="Address 1"
              onChange={(event) => setInput((prevState) => {
                return {
                  ...prevState,
                  address: event.target.value,
                }
              })}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              placeholder="City"
              onChange={(event) => setInput((prevState) => {
                return {
                  ...prevState,
                  city: event.target.value,
                }
              })}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Zip Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="1234AB"
              onChange={(event) => setInput((prevState) => {
                return {
                  ...prevState,
                  zip_code: event.target.value,
                }
              })}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Country</Form.Label>
            <Form.Select
              onChange={(event) => setInput((prevState) => {
                return {
                  ...prevState,
                  country: event.target.value,
                }
              })}
            >
              <option value="">Select...</option>
              {Object.entries(countries).map(([key, value]) => <option key={key} value={key}>{value}</option>)}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="myagency@example.com"
              onChange={(event) => setInput((prevState) => {
                return {
                  ...prevState,
                  email: event.target.value,
                }
              })}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="+31-12345678"
              onChange={(event) => setInput((prevState) => {
                return {
                  ...prevState,
                  phone_number: event.target.value,
                }
              })}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col lg>
          <Form.Group className="mb-3">
            <Form.Label>Agency Information</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              placeholder="Example information for my agency"
              onChange={(event) => setInput((prevState) => {
                return {
                  ...prevState,
                  agency_information: event.target.value,
                }
              })}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col lg>
          <div className="mb-3">
            <small>By creating a profile, you accept that information you provide will be made public</small>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button
            className="w-100"
            variant="success"
            type="submit"
            disabled={loading}>
            {loading ?
              <Spinner animation="border" />
              :
              <span>Create</span>
            }
          </Button>
        </Col>
      </Row>

    </Form>
  )
}

export default AddAgencyForm;