/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import {
  Nav,
  Badge,
  Image,
  Button,
  Dropdown,
  Accordion,
  Navbar,
} from '@themesberg/react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartPie,
  faCalendarPlus,
  faRunning,
  faHome,
  faUserTie,
  faBuilding,
  faTimes,
  faLaptopHouse,
  faUserFriends,
} from '@fortawesome/free-solid-svg-icons';

import SimpleBar from 'simplebar-react';
import { useLocation, Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import routes from '../routes';

import { useAuth } from '../AuthContext'
import useSWR from 'swr';
import fetcher from '../utils/fetcher';
import profileTypes from '../utils/profileTypes';

import logo from './assets/logo-2x.png';

const CollapsableNavItem = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const {
    eventKey,
    title,
    icon,
    children = null,
  } = props;
  const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : '';

  return (
    <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
          <span>
            <span className="sidebar-icon">
              <FontAwesomeIcon icon={icon} />
            </span>
            <span className="sidebar-text">{title}</span>
          </span>
        </Accordion.Button>
        <Accordion.Body className="multi-level">
          <Nav className="flex-column">
            {children}
          </Nav>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

const NavItem = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const {
    setShow,
    title,
    link,
    external,
    target,
    icon,
    image,
    badgeText,
    badgeBg = 'secondary',
    badgeColor = 'primary',
  } = props;

  const classNames = badgeText ? 'd-flex justify-content-start align-items-center justify-content-between' : '';
  const navItemClassName = link === `/${pathname.split("/")[1]}` ? 'active' : '';
  const linkProps = external ? { href: link } : { as: Link, to: link };

  return (
    <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
      <Nav.Link {...linkProps} target={target} className={classNames}>
        <span>
          {icon
            ? (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />
              </span>
            )
            : null}
          {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}
          <span className="sidebar-text">{title}</span>
        </span>
        {badgeText ? (
          <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
        ) : null}
      </Nav.Link>
    </Nav.Item>
  );
};

const PrivateMenu = ({ setShow }) => {
  const { currentUser, currentUserToken } = useAuth();
  const { data: user, error: errorUser } = useSWR(currentUser && currentUserToken ? [`/api/users/me`, {}, currentUserToken] : null, fetcher);

  switch (user?.active_profile_type) {
    case profileTypes.AGENCY:
      return (
        <>
          <NavItem title="My Agency" link={routes.MyAgencyPage.path} icon={faLaptopHouse} setShow={setShow} />
          <NavItem title="My Athletes" link={routes.MyAthletesPage.path} icon={faUserFriends} setShow={setShow} />
        </>
      )
    case profileTypes.COACH:
      return (
        <>
          <NavItem title="Coach Profile" link={routes.AgenciesPage.path} icon={faBuilding} />
        </>
      )
    default:
      return null;
  }
}

const Sidebar = () => {
  const [show, setShow] = useState(false);
  const showClass = show ? 'show' : '';

  const onCollapse = () => setShow(!show);

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        {/* <Navbar.Brand className="me-lg-5" as={Link} to={routes.HomePage.path}> */}
        {/* <img src={logo} alt="Logo" /> */}
        <div className='w-25'>
          {/* <img src={logo} alt="" width="200" height="200"></img> */}
          <Image src={logo} />
        </div>
        {/* </Navbar.Brand> */}
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              {/* <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h6>Hi, Jane</h6>
                  <Button as={Link} variant="secondary" size="xs" to={Routes.Signin.path} className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button>
                </div>
              </div> */}
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              {/* <Navbar.Brand as={Link} to={routes.HomePage.path}> */}
              <div className='w-75'>
                <Image src={logo} />
              </div>

              {/* </Navbar.Brand> */}

              <Dropdown.Divider className="my-3 border-indigo" />

              <NavItem title="Home" link={routes.HomePage.path} icon={faHome} setShow={setShow} />
              <NavItem title="Athletes" link={routes.AthletesPage.path} icon={faRunning} setShow={setShow} />
              <NavItem title="Coaches" link={routes.CoachesPage.path} icon={faUserTie} setShow={setShow} />
              <NavItem title="Agencies" link={routes.AgenciesPage.path} icon={faBuilding} setShow={setShow} />

              <Dropdown.Divider className="my-3 border-indigo" />

              <PrivateMenu setShow={setShow} />

              {/* <NavItem title="Agencies" link={routes.SettingsPage.path} icon={faChartPie} setShow={setShow} />
              <NavItem title="Coaches" link={routes.SettingsPage.path} icon={faChartPie} setShow={setShow} /> */}
              {/* <NavItem title="Company" link={routes.CompanyPage.path} icon={faBriefcase} setShow={setShow} />
              <NavItem title="Team" link={routes.TeamPage.path} icon={faUserFriends} setShow={setShow} /> */}

              {/* <CollapsableNavItem eventKey="create/" title="More" icon={faCalendarPlus}>
                <NavItem title="Home" link={routes.HomePage.path} icon={faChartPie} setShow={setShow} /> */}
              {/* <NavItem title="Shifts" link={routes.ShiftsPage.path} icon={faClipboardList} setShow={setShow} />
                <NavItem title="Availabilities" link={routes.AvailabilitiesPage.path} icon={faCalendarCheck} setShow={setShow} />
                <NavItem title="Schedule" link={routes.SchedulePage.path} icon={faCalendarAlt} setShow={setShow} /> */}
              {/* </CollapsableNavItem> */}

            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};

export default Sidebar;
