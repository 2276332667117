import React from 'react';
import { Link } from "react-router-dom";

const PropertyLabelLink = (props) => {
  const { title, value, link } = props;

  return (
    <div className="d-flex flex-column mb-2">
      <span className="fw-bold">{`${title}:`}</span>
      <Link to={link}>
        <span>{value}</span>
      </Link>
    </div>
  )
}

export default PropertyLabelLink;