import axios from 'axios';

export const signUp = (input) => {
  return axios.post(
    '/api/users/',
    input,
    {
      headers: {
        'Content-Type': 'application/json',
      }
    },
  )
}

export const createAgency = (userId, input, token) => {
  return axios.post(
    `/api/users/me/agencies`,
    input,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    },
  )
}

export const createAthlete = (userId, agencyId, input, token) => {
  return axios.post(
    `/api/users/me/agencies/${agencyId}/athletes`,
    input,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    },
  )
}